<template>
  <v-app>
    <v-dialog class="pa-4" width="400px" v-model="isShowAlert" color="green-grey">
      <v-card>
        <v-card-title class="text-h5">
          <v-icon icon="mdi-alert"></v-icon> ประกาศ
        </v-card-title>
        <v-card-text class="red--text">
          เว็บไซต์ marineportal จะมีการปิดปรับปรุงชั่วคราวเพื่อเพิ่มประสิทธิภาพของระบบ
          ในวันศุกร์ที่ 23 กุมภาพันธ์ 2567 และจะเปิดกลับมาใช้งานได้ในวันที่ 1 มีนาคม
          2567</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" dark variant="text" @click="isShowAlert = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="app">
      <!-- Navbar section -->
      <v-app-bar height="76" class="white" elevation="0">
        <v-app-bar-nav-icon
          v-if="isAdmin"
          @click.stop="drawer = !drawer"
          dark
        ></v-app-bar-nav-icon>
        <a target="_blank" href="https://gistda.or.th"
          ><img
            class="gistda-logo-white"
            style="padding-left: 12px"
            height="30"
            src="@/assets/gistda-logo-white.png"
            alt=""
        /></a>
        <a href="/"
          ><img
            style="padding-left: 12px"
            height="46"
            src="@/assets/logo-gistda-marine-white.png"
            alt=""
        /></a>
        <v-spacer></v-spacer>
        <v-btn color="green" v-if="!$keycloak.authenticated" dark @click="login()">
          เข้าสู่ระบบ
        </v-btn>
        <v-btn
          class="ma-2 mr-0"
          outlined
          v-if="!$keycloak.authenticated"
          dark
          @click="register()"
        >
          สมัครสมาชิก
        </v-btn>
        <div v-else class="d-flex justify-center align-center">
          <div dark class="mr-4 white--text">{{ displayName }}</div>
          <v-btn @click="$keycloak.logout()"> ออกจากระบบ </v-btn>
        </div>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary v-if="isAdmin">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Marine GI Portal </v-list-item-title>
            <v-list-item-subtitle> ส่วนผู้ดูแลระบบ </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item-group active-class="deep-purple--text text--accent-4">
            <v-list-item to="/">
              <v-list-item-icon>
                <v-icon small>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>หน้าหลัก</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/admin/muc-conflict">
              <v-list-item-icon>
                <v-icon small>mdi-application-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ตั้งค่าชั้นข้อมูล</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/admin/muc-conflict-place">
              <v-list-item-icon>
                <v-icon small>mdi-application-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>ตั้งค่าสถานที่เริ่มต้น</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :href="keycloak_url" target="_blank">
              <v-list-item-icon>
                <v-icon small>mdi-account-key</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>เข้าสู่ระบบ Admin Keycloak</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item href="http://100.24.174.202:8501/" target="_blank">
              <v-list-item-icon>
                <v-icon small>mdi-laptop</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>เข้าสู่ระบบ AI Oil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              href="https://marine-dev.gistda.or.th/services-oil/docs"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon small>mdi-laptop</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>GISTDA Web NetCDF Service API</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <!-- content section -->
      <router-view />

      <!-- footer section -->
      <v-footer class="pa-0" color="#185787" dark>
        <v-row class="ma-0 px-8 pt-8 pb-2">
          <v-col class="px-8" cols="12" sm="4" md="4">
            <h2>จิสด้า</h2>
            <br />
            <p>
              สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) สำนักงานใหญ่
              (ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550) เลขที่ 120
              อาคารรวมหน่วยราชการ (อาคารรัฐประศาสนภักดี) ชั้น 6 และชั้น 7 ถนนแจ้งวัฒนะ
              แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210
            </p>
          </v-col>
          <v-divider class="footer-divider" vertical></v-divider>
          <v-col class="px-8" cols="12" sm="4" md="4">
            <h2>ติดต่อเรา</h2>
            <br />
            <div class="d-flex flex-row align-center mb-2">
              <v-icon size="x-large" color="teal-darken-2"> mdi-phone </v-icon>
              <div class="d-flex flex-column">
                <div class="contact-text">โทรศัพท์<br />0 2141 4533, 08 5489 4756</div>
              </div>
            </div>
            <div class="d-flex flex-row align-center my-2">
              <v-icon size="x-large" color="teal-darken-2"> mdi-fax </v-icon>
              <div class="d-flex flex-column">
                <div class="contact-text">โทรสาร<br />0-2143-9596</div>
              </div>
            </div>
            <div class="d-flex flex-row align-center my-2">
              <v-icon size="x-large" color="teal-darken-2"> mdi-email </v-icon>
              <div class="d-flex flex-column">
                <div class="contact-text">อีเมล<br />gid@gistda.or.th</div>
              </div>
            </div>
          </v-col>
          <v-divider class="footer-divider" vertical></v-divider>
          <v-col class="px-8" cols="12" sm="4" md="4">
            <h2>สื่อมีเดีย</h2>
            <br />
            <div class="d-flex flex-row align-center mb-2">
              <a
                class="d-flex flex-row"
                href="https://twitter.com/gistda"
                target="_blank"
              >
                <v-icon size="x-large" color="teal-darken-2"> mdi-twitter </v-icon>
                <div class="d-flex flex-column">
                  <div class="contact-text">TWITTER</div>
                </div>
              </a>
            </div>
            <div class="d-flex flex-row align-center my-2">
              <a
                class="d-flex flex-row"
                href="https://www.facebook.com/gistda/"
                target="_blank"
              >
                <v-icon size="x-large" color="teal-darken-2"> mdi-facebook </v-icon>
                <div class="d-flex flex-column">
                  <div class="contact-text">FACEBOOK</div>
                </div>
              </a>
            </div>
            <div class="d-flex flex-row align-center my-2">
              <a
                class="d-flex flex-row"
                href="https://www.instagram.com/gistda_space/"
                target="_blank"
              >
                <v-icon size="x-large" color="teal-darken-2"> mdi-instagram </v-icon>
                <div class="d-flex flex-column">
                  <div class="contact-text">INSTAGRAM</div>
                </div>
              </a>
            </div>
            <div class="d-flex flex-row align-center my-2">
              <a
                class="d-flex flex-row"
                href="https://www.youtube.com/c/GISTDAspace"
                target="_blank"
              >
                <v-icon size="x-large" color="teal-darken-2"> mdi-youtube </v-icon>
                <div class="d-flex flex-column">
                  <div class="contact-text">YOUTUBE</div>
                </div>
              </a>
            </div>
          </v-col>
        </v-row>

        <!-- Copyright footer -->
        <v-row class="ma-0">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="pa-4"
            style="border-top: 1px solid #ffffff40"
          >
            © {{ new Date().getFullYear() }} GISTDA. All Rights Reserved.
          </v-col>
        </v-row>
      </v-footer>

      <!-- cookie section -->
      <v-alert
        v-if="isShowCookieBanner"
        id="cookie-alert"
        prominent
        dark
        color="blue-grey"
        icon="mdi-cookie"
      >
        <v-row align="center">
          <v-col class="grow">
            เว็บไซต์นี้ใช้คุกกี้ (Cookies)
            เพื่อให้ท่านได้รับประสบการณ์การใช้งานที่ดียิ่งขึ้น
            <a
              style="color: white"
              href="https://www.gistda.or.th/more_news.php?c_id=442&lang=TH"
              target="_blank"
              >นโยบายคุกกี้</a
            >
          </v-col>
          <v-col class="shrink">
            <v-btn @click="acceptCookies()">ยอมรับ</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    items: [
      { title: "หน้าหลัก", icon: "mdi-home" },
      { title: "ตั้งค่า MUC Matrix", icon: "mdi-admin" },
    ],
    isAdmin: false,
    displayName: "",
    isShowCookieBanner: false,
    keycloak_url: process.env.VUE_APP_KEYCLOAK_URL + "admin/marine/console",
    isShowAlert: false,
  }),
  created() {
    let self = this;
    if (!self.$cookies.get("acceptCookies")) {
      self.isShowCookieBanner = true;
    }
  },
  mounted() {
    let self = this;
    if (self.$keycloak.token) {
      self.$keycloak
        .loadUserProfile()
        .then(function (profile) {
          self.displayName = profile.username;
        })
        .catch(function (res) {
          console.log(res);
        });
      localStorage.setItem("token", self.$keycloak.token);

      if (self.$keycloak.realmAccess.roles.includes("manager")) {
        self.isAdmin = true;
      }
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    login() {
      window.location.href = this.$keycloak.createLoginUrl();
    },
    register() {
      console.log(this.$keycloak.register());
    },
    acceptCookies() {
      this.$cookies.set("acceptCookies", true, "10y");
      this.isShowCookieBanner = false;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Prompt", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  // height: 100%;
}

.v-application {
  font-family: "Prompt", Helvetica, Arial, sans-serif !important;
}

.v-application .text-h5 {
  font-family: "Prompt", Helvetica, Arial, sans-serif !important;
}

.v-main {
  background-color: #f0f7ff;
}

.v-toolbar__content {
  background-color: #185787;
}

.footer-divider {
  display: none;
}

#cookie-alert {
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  left: 50%;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: var(--v-secondary-base);
    }
  }
}

.contact-text {
  font-size: 14px;
  color: #d9d9d9;
  margin-left: 8px;
}

@media only screen and (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}

@media only screen and (max-width: 600px) {
  .gistda-logo-white {
    display: none;
  }
  #cookie-alert {
    -webkit-transform: none;
    transform: none;
    left: auto;
    margin: 10px;
  }
}
</style>
