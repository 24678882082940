<template>
  <v-app light>
    <v-main>
      <div
        id="hero"
        :style="{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(24, 87, 135)), url(${bgImage})`,
        }"
      >
        <div class="d-flex flex-column align-center justify-center">
          <img height="120" src="@/assets/logo-gistda-marine-white.png" alt="" />
          <div class="mt-12" id="hero-title">
            ระบบประเมินและติดตามระบบนิเวศทางทะเลและชายฝั่ง
          </div>
          <div id="hero-description">Marine GI Portal</div>
          <a class="mt-6" style="color: #ffffff" @click="scrolldown()">เลื่อนลง</a>
          <span></span>
        </div>
      </div>

      <!-- Marine Portal -->
      <div id="marine-section" style="background-color: #f0f7ff">
        <div class="container">
          <v-row id="marine-portal" class="my-14">
            <v-col
              class="d-flex flex-column justify-center pr-md-16"
              cols="12"
              sm="6"
              md="6"
            >
              <h2 style="font-weight: 700" class="primary--text mb-3">
                คลังข้อมูลภูมิสารสนเทศทางทะเลและชายฝั่ง
              </h2>
              <h2 style="font-weight: 200">
                เรียกดูชั้นข้อมูลด้านระบบนิเวศทางทะเลและชายฝั่ง
                พร้อมสร้างแอปพลิเคชันได้ง่ายๆ
              </h2>
              <div class="subcolor--text my-8">
                อัพโหลดชั้นข้อมูลและสร้างแอปพลิเคชันเกี่ยวกับแผนที่ออนไลน์เพื่อนำเสนอข้อมูล
                โดยไม่ต้องใช้ทักษะการโปรแกรมมิ่ง
                แสดงผลข้อมูลเกี่ยวกับระบบนิเวศทางทะเลและชายฝั่งในรูปแบบแผนที่ (maps),
                ตาราง (tables), กราฟ (charts) และสร้างเว็บไซต์เรื่องราว (Geostories)
                เพื่อนำเสนอ ทั้งหมดนี้อยู่ใน Marine GI Portal
              </div>
              <v-btn :href="geonodeUrl" target="_blank" color="primary" width="220" dark>
                เข้าใช้งาน Portal
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-img
                elevation="4"
                width="100%"
                src="@/assets/home/marine-portal.png"
                alt=""
              />
            </v-col>
          </v-row>
        </div>

        <div class="custom-shape-divider-bottom">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      <!-- MSP Tool -->
      <div style="background-color: #ffffff">
        <div class="container">
          <v-col id="msp-tool">
            <v-row class="d-flex flex-column justify-center align-center my-8">
              <h2 class="primary--text pb-4 text-center">
                เครื่องมือสนับสนุนการวางแผนเชิงพื้นที่ทางทะเล<br />
                Marine Spatial Planning Tools
              </h2>
              <div style="width: 50px; height: 4px; background-color: #61a4bc"></div>
            </v-row>
            <v-row class="mb-10 justify-center">
              <v-col class="px-6" cols="12" sm="4" md="4">
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    to="/msp/muc"
                    class="d-flex flex-column"
                    :elevation="hover ? 6 : 1"
                  >
                    <div>
                      <img class="img-tools" src="@/assets/home/muc.png" alt="" />
                    </div>
                    <div class="d-flex flex-column pa-6">
                      <h3 class="my-3">วิเคราะห์ความขัดแย้งการใช้ประโยชน์</h3>
                      <div
                        style="width: 24px; height: 4px; background-color: #61a4bc"
                      ></div>
                      <div class="subcolor--text sub-title-card my-6">
                        เครื่องมือวิเคราะห์ความขัดแย้งการใช้ประโยชน์เชิงพื้นที่ทางทะเล
                        (Maritime Use Conflict)
                      </div>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col class="px-6" cols="12" sm="4" md="4">
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    to="/msp/mangrove"
                    class="d-flex flex-column"
                    :elevation="hover ? 6 : 1"
                  >
                    <div>
                      <img class="img-tools" src="@/assets/home/mangrove.png" alt="" />
                    </div>
                    <div class="d-flex flex-column pa-6">
                      <h3 class="my-3">วิเคราะห์ข้อมูลสถานภาพป่าชายเลน</h3>
                      <div
                        style="width: 24px; height: 4px; background-color: #61a4bc"
                      ></div>
                      <div class="subcolor--text sub-title-card my-6">
                        เครื่องมือวิเคราะห์และรายงานสถานภาพป่าชายเลนแบบอัตโนมัติ
                        (Mangrove)
                      </div>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col class="px-6" cols="12" sm="4" md="4">
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    to="/msp/oilspill"
                    class="d-flex flex-column"
                    :elevation="hover ? 6 : 1"
                  >
                    <div>
                      <img class="img-tools" src="@/assets/home/oil-spill.jpg" alt="" />
                    </div>
                    <div class="d-flex flex-column pa-6">
                      <h3 class="my-3">วิเคราะห์ข้อมูลคราบน้ำมันในทะเล</h3>
                      <div
                        style="width: 24px; height: 4px; background-color: #61a4bc"
                      ></div>
                      <div class="subcolor--text sub-title-card my-6">
                        เครื่องมือวิเคราะห์การเคลื่อนตัวและการแปรสภาพของคราบน้ำมันในทะเล
                        (Oilspill)
                      </div>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>

              <v-col class="px-6" cols="12" sm="4" md="4">
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    :href="dsasUrl"
                    class="d-flex flex-column"
                    :elevation="hover ? 6 : 1"
                  >
                    <div>
                      <img class="img-tools" src="@/assets/home/dsas.png" alt="" />
                    </div>
                    <div class="d-flex flex-column pa-6">
                      <h3 class="my-3">วิเคราะห์การเปลี่ยนแนวชายฝั่งทะเล</h3>
                      <div
                        style="width: 24px; height: 4px; background-color: #61a4bc"
                      ></div>
                      <div class="subcolor--text sub-title-card my-6">
                        วิเคราะห์การเปลี่ยนแนวชายฝั่งทะเล (Digital Shoreline Analysis
                        System)
                      </div>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>

              <v-col class="px-6" cols="12" sm="4" md="4">
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-card
                    :href="suveryUrl"
                    class="d-flex flex-column"
                    :elevation="hover ? 6 : 1"
                  >
                    <div>
                      <img class="img-tools" src="@/assets/home/survey.png" alt="" />
                    </div>
                    <div class="d-flex flex-column pa-6">
                      <h3 class="my-3">ระบบสำรวจข้อมูลเชิงพื้นที่ทางทะเล</h3>
                      <div
                        style="width: 24px; height: 4px; background-color: #61a4bc"
                      ></div>
                      <div class="subcolor--text sub-title-card my-6">
                        ระบบสำรวจข้อมูลเชิงพื้นที่ทางทะเล (Marine Survey)
                      </div>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>

      <!-- organization -->
      <div class="custom-shape-divider-top">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            class="shape-fill"
          ></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            class="shape-fill"
          ></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>

      <!-- organization -->
      <div style="background-color: #d4e6fa">
        <div id="organization" class="container">
          <v-col class="mb-0">
            <v-row class="d-flex flex-column justify-center align-center my-8">
              <h2 class="primary--text pb-4">หน่วยงานความร่วมมือ</h2>
              <v-divider color="#61A4BC" width="5%" height="5"></v-divider>
              <div
                class="d-flex flex-column justify-center align-center text-center subcolor--text mt-6"
              >
                <div>หน่วยงานที่มีพันธกิจในการปกป้องผลประโยน์ทางทะเลและชายฝั่ง</div>
              </div>
            </v-row>

            <v-row class="mx-0 my-8 flex-row justify-center align-center">
              <div
                class="d-flex flex-row justify-center align-center pa-4"
                v-for="(item, index) in sliderOrg"
                :key="index"
              >
                <div
                  elevation="0"
                  class="d-flex flex-column justify-center align-center"
                  style="width: 120px; height: 120px"
                >
                  <img
                    :width="item.width"
                    :title="item.name"
                    :src="item.img"
                    :alt="item.name"
                  />
                </div>
              </div>
            </v-row>
          </v-col>
        </div>
      </div>
      <!-- <FBCustomerChat /> -->
    </v-main>
  </v-app>
</template>

<script>
// import FBCustomerChat from "@/components/modules/fbCustomerChat.vue";
import bgImage from "@/assets/home/marine-hero-small.jpeg";

export default {
  name: "Home",
  components: {
    // FBCustomerChat,
  },
  data: () => ({
    bgImage: bgImage,
    geonodeUrl: process.env.VUE_APP_GEONODE_URL,
    token: "",
    columns: 4,
    dsasUrl: process.env.VUE_APP_DSAS_URL,
    suveryUrl: process.env.VUE_APP_SURVEY_URL,
    sliderOrg: [
      {
        name: "สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน)",
        img: require("@/assets/logo-org/gistda.png"),
        width: 130,
      },
      {
        name: "กรมทรัพยากรทางทะเลและชายฝั่ง",
        img: require("@/assets/logo-org/dmcr.png"),
        width: 80,
      },
      {
        name: "กรมประมง",
        img: require("@/assets/logo-org/fisheries.png"),
        width: 100,
      },
      {
        name: "ศูนย์อำนวยการรักษาผลประโยชน์ของชาติทางทะเล",
        img: require("@/assets/logo-org/thai-mecc.png"),
        width: 110,
      },
      {
        name: "กรมอุทกศาสตร์ทหารเรือ",
        img: require("@/assets/logo-org/hydro-navy.png"),
        width: 80,
      },
      {
        name: "กรมเจ้าท่า",
        img: require("@/assets/logo-org/md.png"),
        width: 105,
      },
      {
        name: "กรมควบคุมมลพิษ",
        img: require("@/assets/logo-org/pcd.png"),
        width: 100,
      },
      {
        name: "กรมการพัฒนาชุมชน",
        img: require("@/assets/logo-org/cdd.png"),
        width: 105,
      },
      {
        name: "กรมส่งเสริมการปกครองท้องถิ่น",
        img: require("@/assets/logo-org/dla.png"),
        width: 100,
      },
      {
        name: "กรมที่ดิน",
        img: require("@/assets/logo-org/dol.png"),
        width: 135,
      },
      {
        name: "กรมโยธาธิการและผังเมือง",
        img: require("@/assets/logo-org/dpt.png"),
        width: 100,
      },
    ],
    slider: [
      "red",
      "green",
      "orange",
      "blue",
      "pink",
      "purple",
      "indigo",
      "cyan",
      "deep-purple",
      "light-green",
      "deep-orange",
      "blue-grey",
    ],
  }),
  mounted() {
    this.token = localStorage.getItem("token");
    // console.log(this.token)
  },
  methods: {
    scrolldown() {
      const el = document.getElementById("marine-section");
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.v-main {
  background-color: #d4e6fa;
}

#hero {
  height: 100vh;
  min-height: 600px;
  max-height: 1200px;
  color: white;
  background-size: cover !important;
  text-align: center;
  clip-path: ellipse(150% 100% at 50% 0);
  background-color: rgb(240, 247, 255);
}

#hero > div {
  height: calc(100% - 220px);
  font-weight: 700;
}

#hero-title {
  font-size: 42px;
}

#hero-description {
  font-size: 36px;
  font-weight: 400;
}

#scroll-down {
  padding-top: 70px;
}
#hero > div > span {
  width: 24px;
  height: 24px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

.custom-shape-divider-top .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: 100%;
  height: 117px;
}
.custom-shape-divider-bottom {
  background-color: #ffffff;
}
.custom-shape-divider-bottom .shape-fill {
  fill: #f0f7ff;
}

.sub-title-card {
  font-size: 14px;
  color: var(--v-subcolor);
}

.img-tools {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  #hero-title {
    font-size: 26px;
  }

  #hero-description {
    font-size: 24px;
    font-weight: 400;
  }
}
</style>

<style>
.v-carousel__item {
  display: flex;
  align-items: center;
}
#scroll-down a {
  padding-top: 70px;
  color: #ffffff;
  text-decoration: none;
}
</style>
